export const StringValidationUtil = {
  /**
   * Taken from the W3C <input type="email"> specification at
   * https://html.spec.whatwg.org/multipage/input.html#email-state-(type=email)
   * but changed in the domain part to enforce a TLD where W3C also allows for '@localhost'
   * <p>
   * 13.11.2023: added '(?:\.[a-zA-Z0-9-](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9]))*' to ensure that email
   * addresses with the pattern like 'name@subdomain.maindomain.de' are accepted as well
   * <p>
   * Must match the 'EMAIL_REGEX' pattern in Java Class 'ValidationTools.java'
   */
  isValidEmail(email) {
    return (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9](?:\.[a-zA-Z0-9-](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9]))*)$/.test(
        email
      ) && email.length < 256
    )
  },

  /**
   *  See https://stackoverflow.com/a/3802238 for reference
   *   ^                            # start-of-string
   *   (?=.*[0-9])                  # a digit must occur at least once
   *   (?=.*[a-zäöüß])              # a lower case letter must occur at least once
   *   (?=.*[A-ZÄÖÜẞ])              # an upper case letter must occur at least once
   *   (?=.*[@#$%^&+=§*; ... ])     # a special character must occur at least once
   *   .{8,200}                     # anything, at least 8 chars and max 200
   *   $                            # end-of-string
   *
   *  Must match the 'PASSWORD_REGEX' pattern in Java Class 'ValidationTools.java'
   */
  isValidPassword(password) {
    return /^(?=.*[0-9])(?=.*[a-zäöüß])(?=.*[A-ZÄÖÜẞ])(?=.*[@#$%^&+=§*;:!.,|<>\-_'~°?\/()\[\]{}"`´€\\]).{8,200}$/.test(
      password
    )
  },

  isValidUsername(username) {
    return username !== null && /^[a-z0-9_.-]{3,}$/.test(username)
  },

  /**
   *  See https://gist.github.com/DebkanchanSamadder/1eb07af7d9595256535c5c71ea79d66e for reference
   */
  isValidLatitude(latitude) {
    return latitude !== null && /^-?([0-8]?[0-9]|90)(\.[0-9]{1,10})$/.test(latitude)
  },
  isValidLongitude(longitude) {
    return longitude !== null && /^-?([0-9]{1,2}|1[0-7][0-9]|180)(\.[0-9]{1,10})$/.test(longitude)
  }
}
