<template>
  <b-container fluid="xxl">
    <b-card bg-variant="light" class="mb-3">
      <b-card-title class="text-center mb-5" :title="$t('sensor_location.tableTitle')" />
      <GoldflamTable
        :new-item="newSensorLocationObject"
        :all-items="filteredSensorLocations"
        :edit-function="editSensorLocationFunction"
        :delete-function="deleteSensorLocation"
        :table-fields="tableFields"
        stacked="lg"
      >
        <template v-slot:filters>
          <b-col md="3" sm="5">
            <b-select id="cityFilter" v-model="filter.city" :options="citySelectOpts">
              <template #first>
                <b-form-select-option :value="null">Alle Städte</b-form-select-option>
              </template>
            </b-select>
          </b-col>
        </template>
      </GoldflamTable>
      <b-modal
        id="editModal"
        v-model="modalControl.edit.show"
        size="lg"
        centered
        :title="$t('sensor_location.editSensorLocation')"
        :ok-title="$t('general.save')"
        :cancel-title="$t('general.cancel')"
        cancel-variant="outline-secondary"
        :header-bg-variant="headerBgVariant"
        :header-text-variant="headerTextVariant"
        :body-bg-variant="bodyBgVariant"
        :body-text-variant="bodyTextVariant"
        :footer-bg-variant="footerBgVariant"
        :footer-text-variant="footerTextVariant"
        @ok="saveSensorLocation(editSensorLocation)"
        :ok-disabled="$v.$invalid"
        @hidden="resetEditModal"
      >
        <b-form>
          <b-form-group
            class="mt-4 mb-2"
            label-for="editName"
            label-cols="4"
            label-cols-lg="3"
            :label="$t('sensor_location.table.name')"
            :state="validateFormInput('name')"
          >
            <b-form-input id="editName" type="text" v-model="$v.editSensorLocation.name.$model" />
          </b-form-group>

          <b-form-group
            class="mt-4 mb-2"
            label-for="editPostcode"
            label-cols="4"
            label-cols-lg="3"
            :label="$t('sensor_location.table.postcode')"
            :state="validateFormInput('postcode')"
          >
            <b-form-input id="editPostcode" type="number" v-model="$v.editSensorLocation.postcode.$model" />
          </b-form-group>

          <b-form-group
            class="mt-4 mb-2"
            label-for="editCity"
            label-cols="4"
            label-cols-lg="3"
            :label="$t('sensor_location.table.city')"
            :state="validateFormInput('postcode')"
          >
            <b-form-input id="editCity" type="text" v-model="$v.editSensorLocation.city.$model" />
          </b-form-group>

          <b-form-group
            class="mt-4 mb-2"
            label-for="editLatitude"
            label-cols="4"
            label-cols-lg="3"
            :label="$t('sensor_location.table.latitude')"
            :invalid-feedback="$t('sensor_location.invalidFeedback-msg-latitude')"
          >
            <b-form-input
              id="editLatitude"
              type="text"
              placeholder="DD.DDDDDD"
              v-model="$v.editSensorLocation.latitude.$model"
              :state="validateFormInput('latitude')"
            />
          </b-form-group>

          <b-form-group
            class="mt-4 mb-2"
            label-for="editLongitude"
            label-cols="4"
            label-cols-lg="3"
            :label="$t('sensor_location.table.longitude')"
            :invalid-feedback="$t('sensor_location.invalidFeedback-msg-longitude')"
          >
            <b-form-input
              id="editLongitude"
              type="text"
              placeholder="DDD.DDDDDD"
              v-model="$v.editSensorLocation.longitude.$model"
              :state="validateFormInput('longitude')"
            />
          </b-form-group>
          <b-form-group
            class="mt-4 mb-2"
            label-for="editGpsMasked"
            label-cols="4"
            label-cols-lg="3"
            :label="$t('sensor_location.table.gpsMasked')"
            :state="validateFormInput('gps_masked')"
          >
            <b-form-checkbox
              id="editGpsMasked"
              v-model="$v.editSensorLocation.gps_masked.$model"
              name="gps-checkbox"
              :unchecked-value="false"
            />
          </b-form-group>
        </b-form>
      </b-modal>
    </b-card>
  </b-container>
</template>

<script>
import { ApiMixin, RequestConfig } from '@/mixins/ApiMixin'
import { NotificationMixin } from '@/mixins/NotificationMixin'
import GoldflamTable from '@/components/GoldflamTable'
import { GoldflamLatitude, GoldflamLongitude } from '@/util/VuelidateValidators'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { cloneDeep as _cloneDeep } from 'lodash'

export default {
  name: 'ManageSensorLocations',
  mixins: [ApiMixin, NotificationMixin, validationMixin],
  components: { GoldflamTable },

  data() {
    return {
      objectModels: {
        sensorLocation: {
          name: null,
          postcode: '',
          city: '',
          latitude: '',
          longitude: '',
          gps_masked: false
        }
      },
      editSensorLocation: {},
      tableFields: [
        { key: 'id', label: this.$t('general.id'), sortable: true, formatter: 'formatId', width: '50px' },
        { key: 'name', label: this.$t('sensor_location.table.name'), sortable: true },
        { key: 'postcode', label: this.$t('sensor_location.table.postcode'), sortable: true },
        { key: 'city', label: this.$t('sensor_location.table.city'), sortable: true },
        {
          key: 'latitude',
          label: this.$t('sensor_location.table.latitude'),
          formatter: 'formatLatitudeLongitude',
          sortable: true
        },
        {
          key: 'longitude',
          label: this.$t('sensor_location.table.longitude'),
          formatter: 'formatLatitudeLongitude',
          sortable: true
        },
        {
          key: 'gps_masked',
          label: this.$t('sensor_location.table.gpsMasked'),
          formatter: 'translateBoolean',
          sortable: true
        },
        { key: 'crudActions', label: this.$t('general.table.actions'), width: '80px' }
      ],
      sensorLocationList: [],
      headerBgVariant: 'dark',
      headerTextVariant: 'light',
      bodyBgVariant: 'light',
      bodyTextVariant: 'dark',
      footerBgVariant: 'light',
      footerTextVariant: 'dark',
      modalControl: {
        edit: {
          show: false
        },
        delete: {
          show: false
        }
      },
      filter: {
        city: null
      }
    }
  },
  validations: {
    editSensorLocation: {
      name: {
        required
      },
      postcode: {
        required
      },
      city: {
        required
      },
      latitude: {
        GoldflamLatitude
      },
      longitude: {
        GoldflamLongitude
      },
      gps_masked: {
        required
      }
    }
  },
  created() {
    this.editSensorLocation = _cloneDeep(this.objectModels.sensorLocation)
  },
  mounted() {
    this.getAllSensorLocations()
  },
  computed: {
    citySelectOpts() {
      let cities = []
      this.sensorLocationList.forEach(l => {
        cities.push(l.city)
      })
      let uniqueCities = new Set(cities)
      let cityOpts = []
      uniqueCities.forEach(c => {
        cityOpts.push({ value: c, text: c })
      })
      return cityOpts
    },
    filteredSensorLocations() {
      let location = this.sensorLocationList
      if (this.filter.city !== null && this.filter.city !== '') {
        location = location.filter(l => {
          return l.city === this.filter.city
        })
      }
      return location
    },
    newSensorLocationObject() {
      return _cloneDeep(this.objectModels.sensorLocation)
    }
  },
  methods: {
    validateFormInput(prop) {
      const { $dirty, $invalid } = this.$v.editSensorLocation[prop]
      return $dirty ? !$invalid : null
    },
    getAllSensorLocations() {
      let self = this
      this.getRequest(
        '/sensor_locations',
        new RequestConfig().onSuccess(res => {
          self.sensorLocationList = res.data
        })
      )
    },
    saveSensorLocation(sensorLocation) {
      let url = '/sensor_locations'
      let method = 'post'
      if (sensorLocation.id) {
        url += `/${sensorLocation.id}`
        method = 'put'
      }
      let self = this
      this.apiRequest(
        method,
        url,
        sensorLocation,
        new RequestConfig().onSuccess(() => {
          if (sensorLocation.id) {
            self.displaySuccess(self.$t('sensor_location.sensor-location-edit-success-msg'))
          } else {
            self.displaySuccess(self.$t('sensor_location.sensor-location-success-msg'))
          }
          this.getAllSensorLocations()
        })
      )
    },
    editSensorLocationFunction(sensorLocation) {
      this.editSensorLocation = _cloneDeep(sensorLocation)
      if (this.editSensorLocation.latitude !== '') {
        this.editSensorLocation.latitude = this.editSensorLocation.latitude.toFixed(6)
      }
      if (this.editSensorLocation.longitude !== '') {
        this.editSensorLocation.longitude = this.editSensorLocation.longitude.toFixed(6)
      }
      this.modalControl.edit.show = true
    },
    deleteSensorLocation(sensorLocation) {
      let self = this
      const id = sensorLocation.id
      this.deleteRequest(
        `/sensor_locations/${id}`,
        new RequestConfig().onSuccess(() => {
          self.displaySuccess(this.$t('sensor_location.sensor-location-delete-success-msg'))
          this.getAllSensorLocations()
        })
      )
    },
    resetEditModal() {
      this.modalControl.edit.show = false
      this.editSensorLocation = _cloneDeep(this.objectModels.sensorLocation)
      this.$v.$reset()
    }
  }
}
</script>
